import request from '@/utils/request'


// 查询账户流水列表
export function listAccount(query) {
  return request({
    url: '/biz/userAccountBill/list',
    method: 'get',
    params: query
  })
}

// 查询账户流水分页
export function pageAccount(query) {
  return request({
    url: '/biz/userAccountBill/getUserAccountBillPage',
    method: 'get',
    params: query
  })
}
// 用户消费查询
export function getUserAccountBillQueryPage(query) {
  return request({
    url: '/biz/userAccountBill/getUserAccountBillQueryPage',
    method: 'get',
    params: query
  })
}

// 查询账户流水详细
export function getAccount(data) {
  return request({
    url: '/biz/userAccountBill/detail',
    method: 'get',
    params: data
  })
}

// 新增账户流水
export function addAccount(data) {
  return request({
    url: '/biz/userAccountBill/add',
    method: 'post',
    data: data
  })
}

// 修改账户流水
export function updateAccount(data) {
  return request({
    url: '/biz/userAccountBill/edit',
    method: 'post',
    data: data
  })
}

// 删除账户流水
export function delAccount(data) {
  return request({
    url: '/biz/userAccountBill/delete',
    method: 'post',
    data: data
  })
}
